var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"Basic Modal","ok-button-props":{ props: { loading: _vm.loading } },"footer":""},on:{"ok":_vm.handleSubmit,"cancel":_vm.handleCancel},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('div',{staticClass:"form_block"},[_c('a-form-item',{attrs:{"label":"Event name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'event_name',
            {
              initialValue: _vm.event.event_name,
              rules: [
                { required: true, message: 'Event name is required!' },
                { min: 4, message: 'At least 4 characters' },
                { max: 255, message: 'Maximum 255 characters' }
              ]
            }
          ]),expression:"[\n            'event_name',\n            {\n              initialValue: event.event_name,\n              rules: [\n                { required: true, message: 'Event name is required!' },\n                { min: 4, message: 'At least 4 characters' },\n                { max: 255, message: 'Maximum 255 characters' }\n              ]\n            }\n          ]"}],attrs:{"placeholder":"Enter event name"}})],1),_c('a-form-item',{attrs:{"label":"Event id"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'event_id',
            {
              initialValue: _vm.event.url_name,
              rules: [
                { required: true, message: 'Event id is required!' },
                { max: 255, message: 'Maximum 255 characters' }
              ]
            }
          ]),expression:"[\n            'event_id',\n            {\n              initialValue: event.url_name,\n              rules: [\n                { required: true, message: 'Event id is required!' },\n                { max: 255, message: 'Maximum 255 characters' }\n              ]\n            }\n          ]"}],attrs:{"placeholder":"Enter event id"}})],1)],1),_c('div',{staticClass:"form_block"},[_c('a-form-item',{attrs:{"label":"Default Language"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'language',
            {
              initialValue: _vm.event.default_language.name,
            }
          ]),expression:"[\n            'language',\n            {\n              initialValue: event.default_language.name,\n            }\n          ]"}]},_vm._l((_vm.langs.langData),function(lang){return _c('a-select-option',{key:lang.id,attrs:{"value":lang.desc},on:{"click":_vm.changeDefLang}},[_vm._v(" "+_vm._s(lang.desc)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"Status"}},[_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'status',
            {
              initialValue: _vm.event.status,
            }
          ]),expression:"[\n            'status',\n            {\n              initialValue: event.status,\n            }\n          ]"}],staticClass:"status"},[(_vm.event.status === 'active')?_c('a-tooltip',{attrs:{"title":"active","placement":"bottom"}},[_c('a-icon',{staticClass:"font-size-18 green-bulb",attrs:{"type":"bulb","theme":"twoTone","two-tone-color":"#31ba9a"},on:{"click":_vm.changeStatus}})],1):_c('a-tooltip',{attrs:{"title":"inactive","placement":"bottom"}},[_c('a-icon',{staticClass:"font-size-18 grey-bulb",attrs:{"type":"bulb","theme":"twoTone","two-tone-color":"#ccc"},on:{"click":_vm.changeStatus}})],1)],1)])],1),_c('a-form-item',{attrs:{"label":"Other languages"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'languages',
          {
            initialValue: _vm.event.langsName,
          }
        ]),expression:"[\n          'languages',\n          {\n            initialValue: event.langsName,\n          }\n        ]"}],attrs:{"mode":"multiple","placeholder":"Select other languages","showArrow":""},on:{"change":_vm.handleChange}},_vm._l((_vm.event.active_langs),function(lang){return _c('a-select-option',{key:lang.desc},[_vm._v(" "+_vm._s(lang.desc)+" ")])}),1)],1)],1),_c('hr'),_c('div',{staticClass:"d-flex"},[_c('a-col',{staticClass:"text-left",attrs:{"span":12}},[_c('CustomButton',{on:{"click":_vm.handleCancel}},[_vm._v(" Cancel ")])],1),_c('a-col',{staticClass:"text-right",attrs:{"span":12}},[(_vm.event.event_name)?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" Edit ")]):_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" Add ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }