<template>
  <a-modal
    v-model="visible"
    title="Basic Modal"
    @ok="handleSubmit"
    @cancel="handleCancel"
    :ok-button-props="{ props: { loading: loading } }"
    footer=''
  >
    <a-form :form="form">
      <div class="form_block">
        <a-form-item label="Event name">
          <a-input
            placeholder="Enter event name"
            v-decorator="[
              'event_name',
              {
                initialValue: event.event_name,
                rules: [
                  { required: true, message: 'Event name is required!' },
                  { min: 4, message: 'At least 4 characters' },
                  { max: 255, message: 'Maximum 255 characters' }
                ]
              }
            ]"
          />
        </a-form-item>
        <a-form-item label="Event id">
          <a-input
            placeholder="Enter event id"
            v-decorator="[
              'event_id',
              {
                initialValue: event.url_name,
                rules: [
                  { required: true, message: 'Event id is required!' },
                  { max: 255, message: 'Maximum 255 characters' }
                ]
              }
            ]"
          />
        </a-form-item>
      </div>
      <div class="form_block">
        <a-form-item label="Default Language">
          <a-select
            v-decorator="[
              'language',
              {
                initialValue: event.default_language.name,
              }
            ]"
          >
            <a-select-option
              v-for="lang in langs.langData"
              :key="lang.id"
              :value="lang.desc"
              @click="changeDefLang"
            >
              {{ lang.desc }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Status">
          <div
            class="status"
            v-decorator="[
              'status',
              {
                initialValue: event.status,
              }
            ]"
          >
            <a-tooltip title="active" placement="bottom" v-if="event.status === 'active'">
              <a-icon
                @click="changeStatus"
                type="bulb"
                theme="twoTone"
                two-tone-color="#31ba9a"
                class="font-size-18 green-bulb"
              />
            </a-tooltip>
            <a-tooltip title="inactive" placement="bottom" v-else>
              <a-icon
                @click="changeStatus"
                type="bulb"
                theme="twoTone"
                two-tone-color="#ccc"
                class="font-size-18 grey-bulb"
              />
            </a-tooltip>
          </div>
        </a-form-item>
      </div>
      <a-form-item label="Other languages">
        <a-select
          v-decorator="[
            'languages',
            {
              initialValue: event.langsName,
            }
          ]"
          mode="multiple"
          placeholder="Select other languages"
          showArrow
          @change="handleChange"
        >
          <a-select-option v-for="lang in event.active_langs" :key="lang.desc">
            {{ lang.desc }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <hr>
    <div class="d-flex">
      <a-col class="text-left" :span="12">
        <CustomButton @click="handleCancel">
          Cancel
        </CustomButton>
      </a-col>
      <a-col class="text-right" :span="12">
        <a-button type="primary" @click="handleSubmit" v-if="event.event_name">
          Edit
        </a-button>
        <a-button type="primary" @click="handleSubmit" v-else>
          Add
        </a-button>
      </a-col>
    </div>
  </a-modal>
</template>
<script>
import apiClient from '@/services/axios'
import CustomButton from '@/components/custom/button/CustomButton'
import { mapState } from 'vuex'

export default {
  props: {
    event: {
      type: Object,
    },
  },
  components: {
    CustomButton,
  },
  data() {
    return {
      visible: false,
      eventsLangs: [],
      selectedLangs: [],
      form: this.$form.createForm(this, {
        name: 'event',
      }),
      loading: false,
    }
  },
  computed: {
    ...mapState(['langs']),
  },
  methods: {
    getLangId(name) {
      const lang = this.langs.langData.find((item) => {
        return item.desc === name
      })
      return lang.id
    },
    getLangArrId (arr) {
      const langs = []
      arr.forEach(el => {
        this.langs.langData.find((item) => {
          if (item.desc === el) {
            langs.push(item.id)
          }
        })
      })
      return langs
    },
    getLang(name) {
      const lang = this.langs.langData.find((item) => {
        return item.name === name
      })
      return lang
    },
    changeStatus() {
      this.event.status = (this.event.status === 'active') ? this.event.status = 'inactive' : this.event.status = 'active'
    },
    handleChange(value) {
      this.selectedLangs = value
      this.eventsLangs = []
      value.forEach((item) => {
        this.eventsLangs.push(this.langs.langData.find(el => el.desc === item))
      })
      this.eventsLangs = this.eventsLangs.map((item) => {
        return item.id
      })
    },
    changeDefLang(e) {
      this.changeArrActiveLangs(e.key)
    },
    changeArrActiveLangs(lang) {
      // Проверка на наличие lang в Events Languages, если есть удаляем
      this.event.active_langs.forEach((item, index) => {
        if (item.desc === lang) {
          this.event.active_langs.splice(index, 1)
        }
      })
      // Формирование нового списка языков для Events Languages с учетом выбранного языка в Default Language
      this.event.active_langs = [...this.langs.langData]
      this.langs.langData.forEach((el, index) => {
        if (lang === el.desc) {
          this.event.active_langs.splice(index, 1)
        }
      })
      // Удаляем язык из выбранных в Events Languages, если он там есть
      this.selectedLangs.forEach((el, index) => {
        if (lang === el) {
          this.selectedLangs.splice(index, 1)
        }
      })
      // Отрисовка выбранных языков в Events Languages при редактировании
      if (this.event.langsName.length > 0) {
        this.event.langsName.forEach((el, index) => {
          if (lang === el) {
            this.event.langsName.splice(index, 1)
          }
        })
        this.handleChange(this.event.langsName)
      }
    },
    async handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (!this.event.id) {
            await this.createNewEvent({
              url_name: values.event_id,
              event_name: values.event_name,
              status: values.status,
              default_lang_id: this.getLangId(values.language),
              langs: this.getLangArrId([values.language, ...values.languages]),
            })
            this.eventsLangs = []
            return false
          }
          if (this.eventsLangs.length === 0) {
            values.languages.forEach((item) => {
              this.eventsLangs.push(this.langs.langData.find(el => el.desc === item))
            })
            this.eventsLangs = this.eventsLangs.map((item) => {
              return item.id
            })
          }
          await this.editEvent({
            id: this.event.id,
            url_name: values.event_id,
            event_name: values.event_name,
            status: values.status,
            default_lang_id: this.getLangId(values.language),
            langs: this.getLangArrId([values.language, ...values.languages]),
          })
          this.eventsLangs = []
        }
      })
    },
    async createNewEvent(payload) {
      try {
        this.loading = true
        await apiClient.post('admin/events/store', { ...payload })
        this.$notification.success({
          message: 'Success',
          description: 'Event created successfully',
          duration: 2,
        })
        this.$emit('update')
        this.$emit('cancel')
        this.visible = false
      } catch (e) {
        this.$notification.error({
          message: 'Error',
          description: e.message,
        })
      } finally {
        this.loading = false
      }
    },
    async editEvent(payload) {
      try {
        this.loading = true
        await apiClient.patch(`admin/events/${payload.id}/edit`, { ...payload })
        this.$notification.success({
          message: 'Success',
          description: 'Event edited successfully',
          duration: 2,
        })
        this.$emit('update')
        this.$emit('cancel')
        this.visible = false
      } catch (e) {
        this.$notification.error({
          message: 'Error',
          description: e.message,
        })
      } finally {
        this.loading = false
      }
    },
    showModal() {
      this.loading = false
      this.form.resetFields()
      this.visible = true
    },
    handleCancel() {
      this.visible = false
      this.$emit('cancel')
    },
  },
}
</script>

<style lang='scss'>
.form_block {
  display: flex;
  justify-content: space-between;
  & > .ant-row {
    flex: 0 1 45%;
  }
}
[data-kit-theme="default"] .ant-select-selection__placeholder, [data-kit-theme="default"] .ant-select-search__field__placeholder {
  color: #a1a1c1;
}
</style>
